<script lang="ts">
	import type { User } from 'firebase/auth';
	import Header from '$components/Header.svelte';
	import LoginOrSignUpModal from '$components/LoginOrSignUpModal.svelte';
	import { posthog } from '$lib/initPosthog';
	import LandingPageLayout from '$components/LandingPageLayout.svelte';
	import ThreeLandingPageGallery from '$components/ThreeLandingPageGallery.svelte';
	import ThreeRowGallery from '$components/ThreeRowGallery.svelte';
	import CTA from '$components/CTA.svelte';
	import Transition from 'svelte-transition';
	import { onMount } from 'svelte';

	let user: User | null | undefined = undefined;
	let loginOrSignUpModal: LoginOrSignUpModal;
	let loginOrSignupWhichModal: 'login' | 'sign-up' = 'sign-up';

	let showVideo = false;

	onMount(() => {
		showVideo = true;
	});
</script>

<svelte:head>
	<title>Iliad: Expressive AI art generator</title>
	<meta
		name="description"
		content="Create unique artwork, seamless textures, photos, and 3D models with ease. Designed for creative control."
	/>
	<meta
		property="og:image"
		content="https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/aa218b26-95a4-4824-64ea-25be939f2800/public"
	/>
	<script defer type="application/ld+json">
		{
			"@context": "https://schema.org",
			"@type": "WebApplication",
			"name": "Iliad",
			"description": "Expressive AI art generator. Easily create unique artwork, seamless textures, photos, and 3D models.",
			"url": "https://iliad.ai",
			"applicationCategory": "Design",
			"offers": [
				{
					"@type": "Offer",
					"price": "0",
					"priceCurrency": "USD",
					"description": "Free tier: Daily limited access.",
					"url": "https://iliad.ai/plan"
				},
				{
					"@type": "Offer",
					"price": "15",
					"priceCurrency": "USD",
					"description": "Iliad Pro: Full access for $15/month.",
					"url": "https://iliad.ai/plan"
				}
			],
			"featureList": [
				"Art generation",
				"Photo generation",
				"3D model generation",
				"Seamless texture generation",
				"Granular control over generation",
				"Pressure-sensitive brush input",
				"Mobile support"
			],
			"publisher": {
				"@type": "Organization",
				"name": "Iliad",
				"url": "https://iliad.ai"
			},
			"image": "https://iliad.ai/LightMode-FullLogo-TransparentBG.svg"
		}
	</script>
	<link rel="preload" fetchpriority="high" as="image" href="/pale-blue3.avif" type="image/avif" />
	<link
		rel="preload"
		href="/fonts/crimson-text-v19-latin-regular.woff2"
		as="font"
		type="font/woff2"
		crossorigin="anonymous"
	/>
</svelte:head>

<LandingPageLayout bind:user bind:loginOrSignUpModal bind:loginOrSignupWhichModal>
	<div class="h-28"></div>
	<img
		class="inline h-[80px] w-[246px] sm:h-[96px] sm:w-[294.75px]"
		src="/LightMode-FullLogo-TransparentBG.svg"
		alt=""
	/>
	<div class="h-20"></div>
	<div id="headline-section" class="w-full h-fit flex flex-col justify-center items-center">
		<h1
			class="max-w-[375px] sm:max-w-[500px] text-3.5xl sm:text-5xl text-center font-crimson text-zinc-900 z-10"
		>
			AI art generator designed for creative control
		</h1>
	</div>
	<div class="w-full h-fit flex flex-row justify-center items-center">
		<div
			class="w-5/6 h-96 absolute bg-cover"
			style="
						background-image: url('/pale-blue3.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 50%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
		></div>
		<div class="w-full h-72 sm:h-80 flex flex-col justify-center items-center opacity-[99.99%]">
			<div class="w-fit h-fit flex flex-col justify-center items-center">
				<div class="h-14"></div>
				<CTA
					{user}
					href="/canvas"
					eventText="start_creating"
					{loginOrSignUpModal}
					{loginOrSignupWhichModal}
				>
					<i class="fa-solid fa-paintbrush mr-2"></i> Start creating
				</CTA>
				<div class="h-6"></div>
				<a
					href="https://iliadai.typeform.com/to/KxYOgk2r"
					class="h-8 flex flex-row justify-center items-center border-zinc-400 rounded text-cyan-700
							hover:brightness-125"
				>
					<i class="fa-solid fa-caret-right mr-2"></i> How can we improve Iliad for you?
				</a>
			</div>
		</div>
	</div>
	<div class="h-20"></div>
	<h2
		class="w-[340px] sm:w-[520px] md:w-[625px] text-xl md:text-2xl font-semibold opacity-[99.99%]"
	>
		Color sketch to refined art.
	</h2>
	<div class="h-8"></div>
	<div class="w-[340px] sm:w-[520px] md:w-[625px] text-[16px] opacity-[99.99%]">
		<p>
			Iliad's <span class="font-semibold">transform</span> setting gives you granular control over your
			generations.
		</p>
		<div class="h-4"></div>
		<p>
			Start with a color sketch or drag an image onto the canvas, adjust the transform, and guide
			your visual ideas into refined art.
		</p>
		<!-- Transform and riff on your visual ideas. -->
	</div>
	<div class="h-8"></div>
	<div class="flex flex-row justify-center items-center opacity-[99.99%]">
		<div class="flex flex-col justify-center items-start">
			<div class="flex flex-row justify-center items-center">
				<img
					class="w-24 h-24 sm:w-32 sm:h-32 md:w-44 md:h-44 lg:w-56 lg:h-56 rounded shadow-md"
					src="/mushroom-1.avif"
					alt=""
				/>
				<div class="w-[5px]"></div>
				<div class="w-0 sm:w-2"></div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					fill="currentColor"
					class="w-4 h-4 sm:w-6 sm:h-6 text-cyan-500 opacity-70"
				>
					<path d="M10.029 5H0v7.967h10.029V18l9.961-9.048L10.029 0v5z" />
				</svg>
				<div class="w-[2px]"></div>
				<div class="w-0 sm:w-2"></div>
			</div>
			<div class="h-4"></div>
			<div class="w-24 sm:w-32 md:w-44 lg:w-56 h-12 text-center text-xs opacity-70">
				Simple sketch created with Iliad's brush tool.
			</div>
		</div>
		<div class="flex flex-col justify-center items-start">
			<div class="flex flex-row justify-center items-center">
				<img
					class="w-24 h-24 sm:w-32 sm:h-32 md:w-44 md:h-44 lg:w-56 lg:h-56 rounded shadow-md"
					src="/mushroom-2.avif"
					alt=""
				/>
				<div class="w-[5px]"></div>
				<div class="w-0 sm:w-2"></div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					fill="currentColor"
					class="w-4 h-4 sm:w-6 sm:h-6 text-cyan-500 opacity-70"
				>
					<path d="M10.029 5H0v7.967h10.029V18l9.961-9.048L10.029 0v5z" />
				</svg>
				<div class="w-[2px]"></div>
				<div class="w-0 sm:w-2"></div>
			</div>
			<div class="h-4"></div>
			<div class="w-24 sm:w-32 md:w-44 lg:w-56 h-12 text-center text-xs opacity-70">
				Sketch to image with 78% transform. "Blue mushroom with golden flecks"
			</div>
		</div>
		<div class="flex flex-col justify-center items-start">
			<div class="flex flex-row justify-center items-center">
				<img
					class="w-24 h-24 sm:w-32 sm:h-32 md:w-44 md:h-44 lg:w-56 lg:h-56 rounded shadow-md"
					src="/mushroom-3.avif"
					alt=""
				/>
			</div>
			<div class="h-4"></div>
			<div class="w-24 sm:w-32 md:w-44 lg:w-56 h-12 text-center text-xs opacity-70">
				Another pass at the same transform.
			</div>
		</div>
	</div>
	<div class="h-20"></div>
	<h2
		class="w-[340px] sm:w-[520px] md:w-[625px] text-xl md:text-2xl font-semibold opacity-[99.99%]"
	>
		See how Iliad's canvas works.
	</h2>
	<div class="h-8"></div>
	<div
		class="w-[340px] sm:w-[520px] md:w-[625px] h-[21rem] sm:h-[32rem] md:h-[39rem] rounded overflow-hidden bg-zinc-900"
	>
		<Transition
			show={showVideo}
			enter="ease-in-out duration-1000"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in-out duration-1000"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<mux-player
				playback-id="500say01j02e2J3vZIkfzU7oIxzFNi0212OrG7BrdfH2chg"
				metadata-video-title="expressive fantasy 2d character creation"
				metadata-viewer-user-id=""
				primary-color="#ffffff"
				secondary-color="#000000"
				accent-color="#22D3EE"
				autoplay="true"
			></mux-player>
		</Transition>
	</div>
	<div class="h-32"></div>
	<div class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-center items-center">
		<div
			id="3d-object-section"
			class="w-5/6 h-96 absolute bg-cover"
			style="background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/a677e83c-795c-47c9-59ff-1eac6ca86b00/public');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
		></div>
		<div
			class="w-full h-fit flex flex-col lg:flex-row
						   justify-center items-center opacity-[99.99%]"
		>
			<div
				class="w-full flex flex-col justify-center
							   items-start text-left
							   max-w-2xl"
			>
				<h2
					class="text-xl
								   md:text-2xl
								   font-semibold"
				>
					Create 3D models from your images.
				</h2>
				<div class="w-full h-8"></div>
				<div
					class="w-fit lg:w-64 max-w-lg lg:max-w-lg flex flex-col justify-center items-start text-md"
				>
					<p>
						Use the 3D pipeline to turn images from the canvas into 3D meshes or voxel art in
						seconds.
					</p>
					<div class="h-4"></div>
					<p>
						<a
							class="text-cyan-700 hover:brightness-150"
							target="_blank"
							href="https://iliad.ai/journeys/41caf007-9ca8-404b-b63b-3c10087bdc24"
							>See full process details.</a
						>
					</p>
				</div>
			</div>
			<div class="hidden lg:flex lg:flex-shrink-0 w-6"></div>
			<div class="flex lg:hidden h-8"></div>
			<div
				class="flex-shrink-0 w-[21.5rem] h-[300px]
						flex flex-col justify-end items-center shadow-sm rounded
					 	border border-primary-200 cursor-grab active:cursor-grabbing
						bg-primary-50 bg-opacity-25 select-none"
			>
				{#await import('$components/ThreeLandingPageGallery.svelte') then Module}
					<Module.default transitionDuration={300} />
				{/await}
			</div>
		</div>
	</div>
	<div class="h-32"></div>
	<div class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-center items-center">
		<div
			id="texture-authoring-section"
			class="w-5/6 h-96 absolute bg-cover"
			style="background-image: url('/portal-2.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
		></div>
		<div
			class="w-full h-fit flex flex-col lg:flex-row
						   justify-center items-center opacity-[99.99%]"
		>
			<div
				class="w-full flex flex-col justify-center
							   items-start text-left
							   max-w-2xl"
			>
				<h2
					class="text-xl
								   md:text-2xl
								   font-semibold"
				>
					Generate seamless textures and patterns.
				</h2>
				<div class="w-full h-8"></div>
				<div
					class="w-fit lg:w-72 max-w-lg lg:max-w-lg flex flex-col justify-center items-start text-md"
				>
					<p>
						Easily create unique, seamlessly tiled images for textures, patterns, 3D materials,
						skyboxes, and panoramas.
					</p>
					<div class="h-6"></div>
					<a class="text-cyan-700 hover:brightness-150" href="/seamless-texture-generator"
						>Read more.</a
					>
					<div class="h-6"></div>
					<CTA
						{user}
						href="/canvas?tilingMode=true"
						eventText="create_textures"
						{loginOrSignUpModal}
						{loginOrSignupWhichModal}
					>
						<i class="fa-solid fa-paintbrush mr-2"></i> Create textures
					</CTA>
				</div>
			</div>
			<div class="w-[36rem] h-[324px] lg:h-[372px] flex flex-col justify-end items-center">
				<div class="h-0 lg:h-12"></div>
				{#await import('$components/PBRMaterialGraphic.svelte') then Module}
					<Module.default width={300} height={300} transitionDuration={3000} />
				{/await}
				<div class="w-48 h-6 text-center text-xs opacity-70">
					Example of an Iliad seamless texture applied to a sphere.
				</div>
			</div>
		</div>
	</div>
	<div class="h-32"></div>
	<div class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-center items-center">
		<div
			id="character-concept-section"
			class="w-5/6 h-96 absolute bg-cover"
			style="background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/368c0398-5a1d-4b65-25c8-5a732155e100/public');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
		></div>
		<div
			class="w-full h-fit flex flex-col lg:flex-row
						   justify-center items-center opacity-[99.99%]"
		>
			<div
				class="w-full flex flex-col justify-center
							   items-start text-left
							   max-w-2xl"
			>
				<h2
					class="text-xl
								   md:text-2xl
								   font-semibold"
				>
					Craft characters and practical concept art.
				</h2>
				<div class="w-full h-8"></div>
				<div
					class="w-fit lg:w-72 max-w-lg lg:max-w-lg flex flex-col justify-center items-start text-md"
				>
					<p>
						Create illustrations, concept art, portraits, and icons with precise control over your
						vision.
					</p>
					<div class="h-6"></div>
					<a class="text-cyan-700 hover:brightness-150" href="/dnd-character-creator">Read more.</a>
				</div>
			</div>
			<div class="hidden lg:flex lg:flex-shrink-0 w-6"></div>
			<div class="flex lg:hidden h-8"></div>
			<div class="flex flex-col justify-center items-center">
				<div class="flex-shrink-0 w-[19rem] h-fit flex justify-center items-center">
					<img
						class="max-w-full max-h-full rounded shadow-md"
						src="https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/23df0dbe-61ed-4efa-f74f-dc7ee1826100/smaller"
						alt="Landscape created with Iliad"
					/>
				</div>
				<div class="h-4"></div>
				<div class="flex-shrink-0 w-[19rem] h-fit flex justify-center items-center">
					<img
						class="max-w-full max-h-full rounded shadow-md"
						src="https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/cc281db4-44a8-48d1-5eef-bf8b22db5300/smaller"
						alt="Character concept art created with Iliad"
					/>
				</div>
			</div>
		</div>
	</div>
	<div class="h-32"></div>
	<h2 class="w-[340px] sm:w-[520px] md:w-[625px] text-xl md:text-2xl font-semibold">
		Designed to support your creativity.
	</h2>
	<div class="h-8"></div>
	<div
		class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-start items-center flex-wrap"
	>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-rainbow"></i>
			<div class="w-2"></div>
			<p>Style presets</p>
		</div>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-paragraph"></i>
			<div class="w-2"></div>
			<p>Prompt organization</p>
		</div>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-paintbrush"></i>
			<div class="w-2"></div>
			<p>Pressure-sensitive brush tool</p>
		</div>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-cloud-arrow-down"></i>
			<div class="w-2"></div>
			<p>Library to optionally save your work</p>
		</div>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-cubes"></i>
			<div class="w-2"></div>
			<p>Voxel art generator</p>
		</div>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-circle-half-stroke"></i>
			<div class="w-2"></div>
			<p>Seamless texture generator</p>
		</div>
		<a
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
			href="/seamless-texture-checker"
			target="_blank"
		>
			<i class="fa-solid fa-border-all"></i>
			<div class="w-2"></div>
			<p>Seamless texture checker</p>
		</a>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-sailboat"></i>
			<div class="w-2"></div>
			<p>Journeys to share your process</p>
		</div>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-solid fa-mobile"></i>
			<div class="w-2"></div>
			<p>Mobile-friendly website</p>
		</div>
		<div
			class="w-fit h-fit mr-2 mb-2 p-3 flex flex-row justify-center items-center text-center text-xs rounded bg-cyan-200 bg-opacity-50 shadow-md"
		>
			<i class="fa-regular fa-images"></i>
			<div class="w-2"></div>
			<p>Free for up to 50 images per day</p>
		</div>
	</div>
	<div class="h-8"></div>
	<div class="w-[340px] sm:w-[520px] md:w-[625px] text-[16px]">
		We've worked hard to make Iliad feel intuitive, flexible, and maybe even fun to use. We hope it
		becomes a useful part of your process.
	</div>
	<div class="w-full h-fit flex flex-row justify-center items-center">
		<div
			id="cta2-section"
			class="w-5/6 h-48 absolute bg-cover"
			style="background-image: url('/portal-1.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 50%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
		></div>
		<div class="w-full h-48 flex flex-col justify-center items-center opacity-[99.99%]">
			<CTA
				{user}
				href="/canvas"
				eventText="use_iliad"
				{loginOrSignUpModal}
				{loginOrSignupWhichModal}
			>
				<i class="fa-solid fa-paintbrush mr-2"></i> Use Iliad
			</CTA>
		</div>
	</div>
	<div class="h-20"></div>
	<div>
		<div
			id="testimonial-section"
			class="w-5/6 h-96 absolute bg-cover"
			style="
						background-image: url('/pale-blue2.avif');
						background-repeat: no-repeat;
						background-position: center;
						mask-composite: intersect;
						mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
						linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);"
		></div>
		<h2
			class="w-[340px] sm:w-[520px] md:w-[625px] text-xl md:text-2xl font-semibold opacity-[99.99%]"
		>
			Kind words from our users.
		</h2>
		<div class="h-8"></div>
		<div class="opacity-[99.99%]">
			<ul class="w-[340px] sm:w-[520px] md:w-[625px] text-[16px] list-disc text-zinc-700 italic">
				<!-- <li>
					“truly i love iliad so so much it has given me so many usable assets for small passion
					project”
				</li> -->
				<li>
					I love Iliad so, so much. It has given me so many usable assets for small passion
					projects.”
				</li>
				<div class="h-2"></div>
				<!-- <li>“iliad genuinely feels handmade and artisanal”</li> -->
				<li>Iliad genuinely feels handmade and artisanal.”</li>
				<!-- <div class="h-2"></div> -->
				<!-- <li>“THANK YOU FOR MAKING THIS PROGRAM!”</li> -->
				<!-- <div class="h-2"></div> -->
				<!-- <li>“Awesome… Starting to incorporate Iliad textures into our game…”</li> -->
				<!-- <div class="h-2"></div> -->
				<!-- <li>
					“This AI is game changing for an aspiring game developer like myself! No more spending
					hours making 3D models.”
				</li> -->
				<!-- <div class="h-2"></div>
						<li>
							“Iliad gives you the feeling that you can do anything and you're bullet-proof and
							about to be rich.”
						</li> -->
			</ul>
		</div>
		<!-- <div
					class="w-[340px] sm:w-[520px] md:w-[625px] flex flex-row justify-center items-center opacity-[99.99%]"
				>
					<div class="hidden sm:block">
						<img
							class="h-[73px] w-[70px] rounded-[100px] bg-[#2c2d31] border-[#2c2d31] border-[9px]"
							alt="happy user from discord"
							src="/unparalleled-avatar2.png"
							loading="lazy"
						/>
					</div>
					<div class="hidden sm:block">
						<div class="w-2"></div>
					</div>
					<img
						class="rounded-lg w-[340px] h-[57px] sm:w-[431px] sm:h-[72px]"
						alt="testimonial"
						src="/unparalleled.avif"
						loading="lazy"
					/>
				</div> -->
		<div class="h-6"></div>
		<div class="w-[340px] sm:w-[520px] md:w-[625px] text-right text-sm opacity-70">
			<!-- comfybunni -->
			- users from the Discord
		</div>
		<div class="h-8"></div>
		<div class="w-[340px] sm:w-[520px] md:w-[625px] text-[16px] opacity-[99.99%]">
			<i class="w-2"></i>
			<a class="text-cyan-700 hover:brightness-150" href="https://discord.gg/hbb2qD57hM">
				Join Iliad's <i class="fa-brands fa-discord"></i> Discord community
			</a>
			<i class="w-2"></i>
			to share your work, give feedback, and connect with other creatives.
		</div>
	</div>
	<div class="h-12"></div>
	<div class="opacity-[0.01] pointer-events-none">
		<a
			href="https://theresanaiforthat.com/ai/iliad/?ref=featured&v=4042902"
			target="_blank"
			rel="nofollow"
			><img
				alt="taaft-ref"
				width="224"
				height="46"
				src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"
				loading="lazy"
			/></a
		>
	</div>
	<div class="h-12"></div>
</LandingPageLayout>

<!-- others used:
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/368c0398-5a1d-4b65-25c8-5a732155e100/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/9698a2db-e1a3-42f8-3ce5-70233ceff100/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/a677e83c-795c-47c9-59ff-1eac6ca86b00/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/66e33069-b01c-41ba-5128-984c6385bc00/public
https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/c35e35c2-91b7-46f4-1271-6c275db62b00/public
-->
